import get from 'lodash/get';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const storeVariables = ['name'];
const contactVariables = ['first_name', 'last_name'];
const validDateFormats = ['DD/MM/YYYY', 'DD/MM/YY', 'MM/DD/YYYY', 'MM/DD/YY', 'YYYY-MM-DD'];
export const variablesToReplace = {
  'store_name': 'store.caption',
};

function isValidDateFormat(format) {
  return validDateFormats.includes(format);
}

function formatDate(dateString, formatFromUser) {
  const date = dayjs(dateString, validDateFormats, true);

  if(date.isValid()) {
    formatFromUser = formatFromUser?.toUpperCase().trim();
    const finalFormat = isValidDateFormat(formatFromUser) ? formatFromUser : 'DD/MM/YYYY';
    return date.format(finalFormat);
  }

  return dateString;
}

export function replaceVariables(text, store, contact, campaign = null) {
  if(!text) return text;

  const allVariables = {
    store: storeVariables,
    contact: contactVariables,
    campaign: Object.keys(campaign?.custom_data || {}).map(key => `custom_data.${key}`), // support to any variable of custom_data
  };

  Object.entries(allVariables).forEach(([entity, variables]) => {
    variables.forEach((variable) => {
      const variableRegex = new RegExp(`{{\\s*${entity}\\.${variable}(\\.date(_format:([^}]+))?)?\\s*}}`, 'gi');
        text = text.replace(variableRegex, (fullVariable, match, formatSuffix, userFormat) => {

        const value = get(eval(entity), variable, '');

        if(match && match.toLowerCase().startsWith('.date_format:'))
          return formatDate(value, userFormat);
        else if(match && match.toLowerCase().includes('.date'))
          return formatDate(value, 'DD/MM/YYYY'); // default format

        return value;
      });
    });
  });

  return text;
}

export const BOLD_REGEX = /\*\*(.*?)\*\*/g;
export const ITALIC_REGEX = /\*(.*?)\*/g;
export const UNDERLINE_REGEX = /#(.*?)#/g;

export function replaceBold(match, texto) {
  return '<b>' + texto + '</b>';
}

export function replaceItalic(match, texto) {
  return '<i>' + texto + '</i>';
}

export function replaceUnderline(match, texto) {
  return '<u>' + texto + '</u>';
}
